@import 'assets/scss/base';

$navbarMobileHeight: rem(56);

/* Style */
.ButtonBase {
  font-size: 1rem;
}

.TextFieldLabel {
  margin-bottom: $value4;
  color: #696969;
}

/* Utilities */
