.LogoLoadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LogoLoading {
  width: 144px;
  height: 144px;
  fill: #757575;
  animation: scale-down-center 2.5s ease-in-out infinite alternate-reverse both;
  -webkit-animation: scale-down-center 2.5s ease-in-out infinite alternate-reverse both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
  transform: translate3d(0,0,0);
  transform: translateZ(0);
  will-change: transform, opacity, filter;
}

.LogoLoadingSmall {
  width: 48px;
  height: 48px;
  fill: #757575;
  animation: scale-down-center-small 2.5s ease-in-out infinite alternate-reverse both;
  -webkit-animation: scale-down-center-small 2.5s ease-in-out infinite alternate-reverse both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
  transform: translate3d(0,0,0);
  transform: translateZ(0);
  will-change: transform, opacity, filter;
}

.LogoLoadingExtraSmall {
  width: 32px;
  height: 32px;
  fill: #757575;
  animation: scale-down-center-extra-small 2.5s ease-in-out infinite alternate-reverse both;
  -webkit-animation: scale-down-center-extra-small 2.5s ease-in-out infinite alternate-reverse both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
  transform: translate3d(0,0,0);
  transform: translateZ(0);
  will-change: transform, opacity, filter;
}

.LoadingPage {
  width: 100%;
  height: calc(100vh - 72px);
}

@-webkit-keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0.75;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(4px);
    opacity: 1;
  }
}

@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0.75;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 1;
  }
}

@-webkit-keyframes scale-down-center-small {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0.75;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(1.3px);
    filter: blur(1.3px);
    opacity: 1;
  }
}
@keyframes scale-down-center-small {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0.75;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(1.3px);
    filter: blur(1.3px);
    opacity: 1;
  }
}

@-webkit-keyframes scale-down-center-small {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0.75;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(1.3px);
    filter: blur(1.3px);
    opacity: 1;
  }
}

@keyframes scale-down-center-extra-small {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-filter: blur(2px);
    filter: blur(2px);
    opacity: 0.75;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0.7px);
    filter: blur(0.7px);
    opacity: 1;
  }
}

@-webkit-keyframes scale-down-center-extra-small {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-filter: blur(2px);
    filter: blur(2px);
    opacity: 0.75;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0.7px);
    filter: blur(0.7px);
    opacity: 1;
  }
}

/* Circular Loading */
.CircularLoading {
  position: relative;
  display: inline-flex;
}

.CircularLoadingLabelCenter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CircularLoadingButtonCenter {
  max-height: 100%;
}
