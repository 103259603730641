@import 'assets/scss/base';

/* Style */
.DialogPaper {
  max-width: rem(370);
}

.DialogContentWrapper {
  max-width: calc(100% - #{$value24});
  margin-left: auto;
  margin-right: auto;
  padding-bottom: rem(50);
}

.DialogContentTitle {
  margin-bottom: rem(16);
}

/* Responsive */
@include media-smallscreen {
  .DialogContentWrapper {
    width: rem(230);
  }
}
