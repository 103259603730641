
  .CreditCardInfoIcon {
    margin-right: 10px;
    width: 23.5px;
    height: 15.5px;
  }

  .IconStroke {
    fill: none;
    stroke: currentColor;
  }
  