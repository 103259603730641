//
//  VARIABLES
// --------------------------------------------------

$fontSizeBase: 16; // font size base in px
$fontFamily: 'Nunito Sans', sans-serif;

// Screen Resolution Variables
$smallscreen: 600px;
$mediumscreen: 1024px;
$largescreen: 1440px;
$xlargescreen: 1920px;

//Color
$colorPrimary: #000;

$colorSecondary: #f9bd19;
$colorSecondaryHover: rgba(249, 189, 25, 0.04);
$colorSecondaryFocus: rgba(249, 189, 25, 0.12);
$colorSecondaryOverlay: rgba(249, 189, 25, 0.24);

$colorSuccess: #c5dd2e;
$colorError: #eb5757;

$colorTextLight: #696969;
$colorTextLighter: #848484;

$colorBlue: #0085ff;
$colorMagenta: #db00ff;

$buttonSecondary: $colorSecondary;
$buttonSecondaryHover: $colorSecondaryHover;
$buttonSecondaryFocus: $colorSecondaryFocus;
$buttonSecondaryOverlay: rgba(249, 189, 25, 0.24);
$buttonSecondaryRipple: rgba(249, 189, 25, 0.1);
$buttonSecondarySelectedOverlay: rgba(249, 189, 25, 0.08);
$buttonDisabledColor: rgba(255, 255, 255, 0.38);
// $buttonDisabledBackground: $colorWhiteFocus;
$buttonErrorColor: $colorError;
$textGray: rgba(255, 255, 255, 0.6);

$background: #fff;
$backgroundTransparent: rgba(222, 222, 222, 0.8);
$backgroundLight: #dedede;
$backgroundLighter: #696969;
$dialogSurfaces: #383838;
$colorBackgroundInput: rgba(255, 255, 255, 0.07);
$colorSurfaceSheetBar: $backgroundLighter;
$backdropColor: rgba(0, 0, 0, 0.5);

// Border
$borderPrimary: 1px solid #dedede;
// $borderOnSurface: 1px solid $colorWhiteFocus;
// $borderSecondary: 1px solid ${secondary.main};
// $borderWhiteMedTextOnDark: 1px solid $colorWhiteMedTextOnDark;
// $borderWhiteHiTextOnDark: 1px solid $colorWhiteHiTextOnDark;
// $borderHairlineBright: $borderWhiteMedTextOnDark;

// Shadow
$shadowCard: 0 4px 8px rgba(0, 0, 0, 0.25);

//Page Padding
$pageContentPadding: 24px 72px;
$pageContentPaddingMediumDown: 24px;

//Navbar height
$navbarHeight: 72px;
$statusBarHeight: 53px;

// Duration
$transitionDuration: 0.3s;
