@import 'assets/scss/base';

/* Style */
.ColorSecondary {
  color: $colorSecondary;
}

.ColorLight {
  color: $colorTextLight;
}

.ColorError {
  color: $colorError;
}
