@import 'assets/scss/base';

/* Style */
.DialogPaper {
  min-width: rem(370);
  max-width: calc(100% - 24px);
  width: auto;
}

.DialogContentWrapper {
  padding-left: $value24;
  padding-right: $value24;
}

.DialogContentWrapperWide {
  min-width: rem(300);
}

.MintSuccessContainer {
  padding-left: $value60;
  padding-right: $value60;
  padding-bottom: rem(46);
}

.LoadingContainer {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $backgroundTransparent;
}

/*  */
.ContainerWithMaxWidthWide {
  max-width: rem(300);
}

/* Utlis */
.MaxWidth170 {
  max-width: rem(170);
}

/* Responsive */
@include media-smallscreen {
  .DialogContentWrapper {
    padding-left: $value36;
    padding-right: $value36;
  }
}
