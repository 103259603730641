html, body, #root {
  height: 100%;
}

html,
body {
  background-color: #FFFFFF;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-corner,
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  &::-webkit-scrollbar:hover,
  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  &::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:vertical {
    background: #DEDEDE;
    border-radius: 100px;
    background-clip: padding-box;
    border: 1px solid transparent;
    min-height: 10px;
  }

  &::-webkit-scrollbar-thumb:horizontal,
  ::-webkit-scrollbar-thumb:horizontal {
    background: #DEDEDE;
    border-radius: 100px;
    background-clip: padding-box;
    border: 1px solid transparent;
    min-width: 10px;
  }

  &::-webkit-scrollbar-thumb:vertical:active,
  &::-webkit-scrollbar-thumb:horizontal:active,
  ::-webkit-scrollbar-thumb:vertical:active,
  ::-webkit-scrollbar-thumb:horizontal:active {
    background: rgba(0, 0, 0, 0.24);
    border-radius: 100px;
    background-clip: padding-box;
  }
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*  */
.swiper-scrollbar {
  background: none;
}

.swiper-scrollbar-drag {
  background-color: #DEDEDE;
}
