@import 'assets/scss/base';

/* Global Styles */
/* UTILS */
.Opacity0 {
  opacity: 0 !important;
}

.Opacity50,
.Hover\:Opacity50:hover {
  opacity: 0.5 !important;
}

.Opacity75 {
  opacity: 0.75 !important;
}

.Opacity100 {
  opacity: 1 !important;
}

/*  */
.WidthFull {
  width: 100% !important;
}

.MaxWidthFull {
  max-width: 100% !important;
}

.MaxHeightFull {
  max-height: 100% !important;
}

.MinWidth0 {
  min-width: 0 !important;
}

.MinWidthFull {
  min-width: 100% !important;
}

.HeightFull {
  height: 100% !important;
}

.Width1 {
  width: 1% !important;
  min-width: 0 !important;
}

.Height1 {
  height: 1% !important;
  min-height: 0 !important;
}

.Width1\/2 {
  width: 50% !important;
}

/* Position */
.PositionRelative {
  position: relative !important;
}

.PositionAbsolute {
  position: absolute !important;
}

.PositionFixed {
  position: fixed !important;
}

.PositionAbsoluteCenterX {
  position: absolute !important;
  left: 50% !important;
  transform: translate(-50%, 0);
}

/* Top / Right / Bottom / Left */
.Top0 {
  top: 0px !important;
}

.TopHalf {
  top: 50% !important;
}

.Left0 {
  left: 0px !important;
}

.Right0 {
  right: 0px !important;
}

.Bottom0 {
  bottom: 0px !important;
}

/* Z-Index */
.ZIndex1 {
  z-index: 1 !important;
}

.ZIndex2 {
  z-index: 2 !important;
}

.ZIndexInvisible {
  z-index: -999 !important;
}

/* Utils - Display */
.DisplayBlock {
  display: block !important;
}

.DisplayInlineBlock {
  display: inline-block !important;
}

.DisplayNone {
  display: none !important;
}

.DisplayFlex {
  display: flex !important;
}

.DisplayInlineFlex {
  display: inline-flex !important;
}

.DisplayFlexColumn {
  display: flex !important;
  flex-direction: column !important;
}

.DisplayInline {
  display: inline !important;
}

/* Utils - Order */
.Order1 {
  order: 1 !important;
}

.Order2 {
  order: 2 !important;
}

/* Utils - Vertical Align */
.VAlignMiddle {
  vertical-align: middle !important;
}

/* Utils - Flexbox */
.Flex1 {
  flex: 1 1 0% !important;
}

.FlexAuto {
  flex: 1 1 auto !important;
}

.AlignItemsCenter {
  align-items: center !important;
}

.AlignItemsFlexStart {
  align-items: flex-start !important;
}

.AlignItemsFlexEnd {
  align-items: flex-end !important;
}

.AlignContentCenter {
  align-content: center !important;
}

.JustifyContentFlexStart {
  justify-content: flex-start !important;
}

.JustifyContentFlexEnd {
  justify-content: flex-end !important;
}

.JustifyContentCenter {
  justify-content: center !important;
}

.JustifyContentSpaceBetween {
  justify-content: space-between !important;
}

.JustifyContentSpaceEvenly {
  justify-content: space-evenly !important;
}

.FlexWrap {
  flex-wrap: wrap !important;
}

/* Utils - Padding */
.Padding0 {
  padding: 0px !important;
}

.Padding4 {
  padding: 4px !important;
}

.Padding6 {
  padding: 6px !important;
}

.Padding8 {
  padding: 8px !important;
}

.Padding12 {
  padding: 12px !important;
}

.Padding16 {
  padding: 16px !important;
}

.Padding24 {
  padding: 24px !important;
}

.PaddingX2 {
  padding-right: 2px !important;
  padding-left: 2px !important;
}

.PaddingX4 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.PaddingX8 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.PaddingX12 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.PaddingX14 {
  padding-right: 14px !important;
  padding-left: 14px !important;
}

.PaddingX16 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.PaddingX20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.PaddingX24 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.PaddingX32 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.PaddingX60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.PaddingY0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.PaddingY2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.PaddingY4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.PaddingY6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.PaddingY8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.PaddingY12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.PaddingY16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.PaddingY24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.PaddingTop0 {
  padding-top: 0px !important;
}

.PaddingTop4 {
  padding-top: 4px !important;
}

.PaddingTop8 {
  padding-top: 8px !important;
}

.PaddingTop12 {
  padding-top: 12px !important;
}

.PaddingTop16 {
  padding-top: 16px !important;
}

.PaddingTop20 {
  padding-top: 20px !important;
}

.PaddingTop24 {
  padding-top: 24px !important;
}

.PaddingTop28 {
  padding-top: 28px !important;
}

.PaddingTop32 {
  padding-top: 32px !important;
}

.PaddingTop56 {
  padding-top: 56px !important;
}

.PaddingTop64 {
  padding-top: 64px !important;
}

.PaddingTop76 {
  padding-top: 76px !important;
}

.PaddingTop100 {
  padding-top: 100px !important;
}

.PaddingTop116 {
  padding-top: 116px !important;
}

.PaddingLeft0 {
  padding-left: 0px !important;
}

.PaddingLeft8 {
  padding-left: 8px !important;
}

.PaddingLeft12 {
  padding-left: 12px !important;
}

.PaddingLeft16 {
  padding-left: 16px !important;
}

.PaddingLeft20 {
  padding-left: 20px !important;
}

.PaddingLeft24 {
  padding-left: 24px !important;
}

.PaddingLeft28 {
  padding-left: 28px !important;
}

.PaddingLeft32 {
  padding-left: 32px !important;
}

.PaddingLeft44 {
  padding-left: 44px !important;
}

.PaddingLeft72 {
  padding-left: 72px !important;
}

.PaddingRight0 {
  padding-right: 0px !important;
}

.PaddingRight8 {
  padding-right: 8px !important;
}

.PaddingRight12 {
  padding-right: 12px !important;
}

.PaddingRight16 {
  padding-right: 16px !important;
}

.PaddingRight20 {
  padding-right: 20px !important;
}

.PaddingRight24 {
  padding-right: 24px !important;
}

.PaddingBottomFull {
  padding-bottom: 100% !important;
}

.PaddingBottom4 {
  padding-bottom: 4px !important;
}

.PaddingBottom6 {
  padding-bottom: 6px !important;
}

.PaddingBottom8 {
  padding-bottom: 8px !important;
}

.PaddingBottom12 {
  padding-bottom: 12px !important;
}

.PaddingBottom16 {
  padding-bottom: 16px !important;
}

.PaddingBottom20 {
  padding-bottom: 20px !important;
}

.PaddingBottom24 {
  padding-bottom: 24px !important;
}

.PaddingBottom36 {
  padding-bottom: 36px !important;
}

.PaddingBottom40 {
  padding-bottom: 40px !important;
}

.PaddingBottom44 {
  padding-bottom: 44px !important;
}

.PaddingBottom46 {
  padding-bottom: 46px !important;
}

.PaddingBottom50 {
  padding-bottom: 50px !important;
}

.PaddingBottom64 {
  padding-bottom: 64px !important;
}

.PaddingBottom72 {
  padding-bottom: 72px !important;
}

.PaddingBottom88 {
  padding-bottom: 88px !important;
}

.PaddingBottom100 {
  padding-bottom: 100px !important;
}

.PaddingBottom120 {
  padding-bottom: 120px !important;
}

@include media-smallscreen {
  .Sm\:MarginBottom32 {
    margin-bottom: 32px !important;
  }
}

/* Utils - Margin */
.MarginAuto {
  margin: auto !important;
}

.Margin0 {
  margin: 0px !important;
}

.Margin4 {
  margin: 4px !important;
}

.Margin8 {
  margin: 8px !important;
}

.Margin32 {
  margin: 32px !important;
}

.MarginYAuto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.MarginY4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.MarginY8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.MarginY48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.MarginXAuto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.MarginX0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.MarginX4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.MarginX8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.MarginX16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.-MarginX9 {
  margin-left: -9px !important;
  margin-right: -9px !important;
}

.-MarginX12 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.-MarginX16 {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

.MarginTop4 {
  margin-top: 4px !important;
}

.MarginTop8 {
  margin-top: 8px !important;
}

.MarginTop10 {
  margin-top: 10px !important;
}

.MarginTop12 {
  margin-top: 12px !important;
}

.MarginTop16 {
  margin-top: 16px !important;
}

.MarginTop18 {
  margin-top: 18px !important;
}

.MarginTop20 {
  margin-top: 20px !important;
}

.MarginTop24 {
  margin-top: 24px !important;
}

.MarginTop28 {
  margin-top: 28px !important;
}

.MarginTop32 {
  margin-top: 32px !important;
}

.MarginTop36 {
  margin-top: 36px !important;
}

.MarginTop40 {
  margin-top: 40px !important;
}

.MarginTop48 {
  margin-top: 48px !important;
}

.MarginTop56 {
  margin-top: 56px !important;
}

.MarginTop64 {
  margin-top: 64px !important;
}

.-MarginTop10 {
  margin-top: -10px !important;
}

.MarginBottom0 {
  margin-bottom: 0px !important;
}

.MarginBottom4 {
  margin-bottom: 4px !important;
}

.MarginBottom8 {
  margin-bottom: 8px !important;
}

.MarginBottom12 {
  margin-bottom: 12px !important;
}

.MarginBottom16 {
  margin-bottom: 16px !important;
}

.MarginBottom20 {
  margin-bottom: 20px !important;
}

.MarginBottom24 {
  margin-bottom: 24px !important;
}

.MarginBottom28 {
  margin-bottom: 28px !important;
}

.MarginBottom32 {
  margin-bottom: 32px !important;
}

.MarginBottom36 {
  margin-bottom: 36px !important;
}

.MarginBottom40 {
  margin-bottom: 40px !important;
}

.MarginBottom42 {
  margin-bottom: 42px !important;
}

.MarginBottom44 {
  margin-bottom: 44px !important;
}

.MarginBottom48 {
  margin-bottom: 48px !important;
}

.MarginBottom50 {
  margin-bottom: 50px !important;
}

.MarginBottom56 {
  margin-bottom: 56px !important;
}

.MarginBottom64 {
  margin-bottom: 64px !important;
}

.MarginBottom72 {
  margin-bottom: 72px !important;
}

.MarginBottom78 {
  margin-bottom: 78px !important;
}

.MarginBottom88 {
  margin-bottom: 88px !important;
}

.MarginBottom124 {
  margin-bottom: 124px !important;
}

.-MarginBottom4 {
  margin-bottom: -4px !important;
}

.-MarginBottom8 {
  margin-bottom: -8px !important;
}

.-MarginBottom10 {
  margin-bottom: -10px !important;
}

.MarginRightAuto {
  margin-right: auto !important;
}

.MarginRight0 {
  margin-right: 0px !important;
}

.MarginRight4 {
  margin-right: 4px !important;
}

.MarginRight8 {
  margin-right: 8px !important;
}

.MarginRight12 {
  margin-right: 12px !important;
}

.MarginRight16 {
  margin-right: 16px !important;
}

.MarginRight24 {
  margin-right: 24px !important;
}

.-MarginRight8 {
  margin-right: -8px !important;
}

.-MarginRight12 {
  margin-right: -12px !important;
}

.-MarginRight16 {
  margin-right: -16px !important;
}

.MarginLeftAuto {
  margin-left: auto !important;
}

.MarginLeft0 {
  margin-left: 0 !important;
}

.MarginLeft4 {
  margin-left: 4px !important;
}

.MarginLeft8 {
  margin-left: 8px !important;
}

.MarginLeft10 {
  margin-left: 10px !important;
}

.MarginLeft12 {
  margin-left: 12px !important;
}

.MarginLeft16 {
  margin-left: 16px !important;
}

.MarginLeft20 {
  margin-left: 20px !important;
}

.MarginLeft24 {
  margin-left: 24px !important;
}

.-MarginLeft8 {
  margin-left: -8px !important;
}

.-MarginLeft16 {
  margin-left: -16px !important;
}

/* Utils - Background */
.BgCenter {
  background-position: center !important;
}

.BgColorPrimary {
  background-color: $background !important;
}

.BgColorBackgroundLight {
  background-color: $backgroundLight !important;
}

.BgColorBackgroundLighter {
  background-color: $backgroundLighter !important;
}

.BgColorBackgroundDialog {
  background-color: $dialogSurfaces !important;
}

.BgColorSecondary {
  background-color: $colorSecondary !important;
}

.BgColorBlue {
  background-color: $colorBlue !important;
}

.BgColorMagenta {
  background-color: $colorMagenta !important;
}

.BgCover {
  background-size: cover !important;
}

/* Utils - Border */
// .BorderColorOnSurface {
//   border-color: $colorWhiteFocus !important;
// }

.Border0 {
  border: 0 !important;
}

.BorderRounded {
  border-radius: $value4 !important;
}

.BorderRoundedFull {
  border-radius: 9999px !important;
}

.BorderPrimary {
  border: $borderPrimary !important;
}

.BorderTopPrimary {
  border-top: $borderPrimary !important;
}

.BorderRightPrimary {
  border-top: $borderPrimary !important;
}

.BorderRightPrimary {
  border-right: $borderPrimary !important;
}

.BorderLeftPrimary {
  border-left: $borderPrimary !important;
}

.BorderBottomPrimary {
  border-bottom: $borderPrimary !important;
}

.BorderRoundedNone {
  border-radius: 0 !important;
}

.BorderRoundedBNone {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.BorderRoundedTNone {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

/* Utils - Outline */
.Outline0 {
  outline: 0 !important;
}

/* Utils - List */
.ListNone {
  list-style: none;
}

/* Utils - Object */
.ObjectScaleDown {
  object-fit: scale-down !important;
}

.ObjectContain {
  object-fit: contain !important;
}

.ObjectCover {
  object-fit: cover !important;
}

.ObjectCenter {
  object-position: center !important;
}

/* Utils - Typography */
.FontLight {
  font-weight: 300 !important;
}

.FontNormal {
  font-weight: normal !important;
}

.FontMedium {
  font-weight: 500 !important;
}

.FontSemibold {
  font-weight: 600 !important;
}

.FontBold {
  font-weight: bold !important;
}

.FontExtrabold {
  font-weight: 800 !important;
}

// Font Size
.FontSize0 {
  font-size: 0 !important;
}

.FontSize16 {
  font-size: $value16 !important;
}

.FontSize18 {
  font-size: $value18 !important;
}

.FontSize20 {
  font-size: $value20 !important;
}

.FontSize24 {
  font-size: $value24 !important;
}

//
.TextLeft {
  text-align: left !important;
}

.TextRight {
  text-align: right !important;
}

.TextCenter {
  text-align: center !important;
}

.TextUnderline {
  text-decoration: underline !important;
}

.TextColorInherit {
  color: inherit !important;
}

.TextColorPrimary {
  color: $colorPrimary !important;
}

.TextColorSecondary {
  color: $colorSecondary !important;
}

.TextColorSuccess {
  color: $colorSuccess !important;
}

.TextColorError {
  color: $colorError !important;
}

.TextColorLight {
  color: $colorTextLight !important;
}

.TextColorDisabled {
  color: $textGray !important;
}

.TextUppercase {
  text-transform: uppercase !important;
}

.TextNormalcase {
  text-transform: none !important;
}

.TextCapitalize {
  text-transform: capitalize !important;
}

.LineHeight1 {
  line-height: 1 !important;
}

/* Utils - Word Break */
.BreakWords {
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}

.Truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

/* Utils - Whitespace */
.WhitespaceNoWrap {
  white-space: nowrap !important;
}

/* Utils - Rotate */
.Rotate180 {
  transform: rotate(180deg) !important;
}

/* Utils - Visibility */
.Invisible {
  visibility: hidden;
}

/* Utils - Misc */
.OverflowAuto {
  overflow: auto !important;
}

.OverflowXHidden {
  overflow-x: hidden !important;
}

.OverscrollContain {
  overscroll-behavior: contain !important;
}

.OverscrollXContain {
  overscroll-behavior-x: contain !important;
}

.OverflowHidden {
  overflow: hidden !important;
}

.PointerEventsNone {
  pointer-events: none !important;
}

.PointerEventsAuto {
  pointer-events: auto !important;
}

.CursorDefault {
  cursor: default !important;
}

.CursorPointer {
  cursor: pointer !important;
}

.UserSelectNone {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.ContentInvisible {
  content-visibility: hidden !important;
}

.BoxBorder {
  box-sizing: border-box;
}

.BoxContent {
  box-sizing: content-box;
}

/* Playform */
.Container {
  width: 100%;
  max-width: rem(1200);
  margin-left: auto;
  margin-right: auto;
}

.Container {
  padding-left: $value12;
  padding-right: $value12;
}

@include media-smallscreen {
  .Container {
    padding-left: $value24;
    padding-right: $value24;
  }
}

@include media-mediumscreen {
  .Container {
    padding-left: $value72;
    padding-right: $value72;
  }
}

@include media-largescreen {
  .Container {
    max-width: rem(1440);
    padding-left: rem(192);
    padding-right: rem(192);
  }
}
