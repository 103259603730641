@import 'assets/scss/base';

/* Style */
.MarginTop4 {
  margin-top: $value4;
}

.LoadingContainer {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  justify-content: center;
}

/* Card Collection */
// .CardCollectionPriceTooltip {
//   position: relative;
//   // left: 100%;
// }

.CardCollectionPriceTooltipIcon {
  font-size: $value16;
}

.CardCollectionWithLinkOverlay {
  &:hover .CardCollectionLinkOverlayWrapper {
    opacity: 0.99;
  }
}

.CardCollectionLinkOverlayWrapper {
  opacity: 0;
  transition: opacity 100ms linear;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(18, 18, 18, 0.6);
  }
}

/*  */
