@import 'assets/scss/base';

/* Style */
.ImageLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EmptyImage {
  width: 100%;
}

/*  */
.DivImage {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.DivImageInner {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Image Thumbnail Empty */
.ImgEmptyThumbnail {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  object-position: center;
}

/* Image Scrambled */
.ImageScrambledContainer {
  position: relative;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.ImageScrambledItem {
  position: absolute;
  z-index: 1;
}

.ImageScrambledImg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/*  */
.DisplayBlock {
  display: block;
}

.FullWidth {
  width: 100%;
}

.FullHeight {
  height: 100%;
}

.BackgroundDefault {
  background: #dedede;
}

.Bordered {
  border: 1px solid $borderPrimary;
}
