@import 'assets/scss/base';

/* Style */
.TooltipWithClickContainer {
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}

/* Responsive */
@include media-smallscreen {
}
